import { ReactNode } from "react";
import { TOKEN_KEY, API_URL, BANK_CODES } from "./../../constants";
import { useParams } from "react-router-dom";
import {
    useShow,
    IResourceComponentsProps,
    useTranslate,
    useUpdate,
    useCustom,
    useLink,
    useExport
} from "@refinedev/core";
import { List, NumberField, RefreshButton, ExportButton } from "@refinedev/antd";

import { InvoiceActions, InvoiceStatus } from "components";

import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    LoadingOutlined,
    MobileOutlined,
} from "@ant-design/icons";

import {
    Row,
    Col,
    Button,
    Steps,
    Grid,
    Space,
    Avatar,
    Typography,
    Card,
    Table,
    Skeleton,
    Timeline,
    Input,
    Tag,
    Image,
    Alert,
    Descriptions
} from "antd";

const { Paragraph } = Typography;
import dayjs from "dayjs";

import { BikeWhiteIcon } from "components/icons";
import { useOrderCustomKbarActions } from "hooks";
import { IEvent, IInvoice, IInvoiceStatus, IProduct, ISplit } from "interfaces";

import {
    Customer,
    CustomerBoxContainer,
    CustomerInfoBox,
    CustomerInfoBoxText,
    CustomerInfoText,
    PageHeader,
    Product,
    ProductFooter,
    ProductText,
} from "./styled";

const { useBreakpoint } = Grid;
const { Text } = Typography;

export const InvoiceShow: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const screens = useBreakpoint();
    const { queryResult } = useShow<IInvoice>();
    const { data } = queryResult;
    const { mutate } = useUpdate();
    const record = data?.data;
    const Link = useLink();

    console.log(record?.splitAll);
    
    // const split : any = record?.splitAll?.filter((item : any) => item.type!=="platform"); // ?.sort((a : any, b : any) => b.amount - a.amount);
    const split : any = record?.splitAll?.map((item : any) => {
        if(item.type!=="platform"){
            return {
                ...item
            }
        } else {
            return {
                ...item,
                name: "Custos e Taxas",
                amount: (Number(record.fees) + Number(record?.splitAll.filter((item : any) => item.type!=="platform").reduce((prev : any,curr : any) => prev + Number(curr.fees ?? 0),0)))
            }
        }
    });

    const total : any = (record?.gross ?? 0);

    let statusText = record?.statusText ?? "" as const;

    const currentBreakPoints = Object.entries(screens).filter((screen) => !!screen[1]).map((screen) => screen[0]);

    const { triggerExport, isLoading: exportLoading } = useExport<any>({
        meta: {
            id: "bar",
        },
        mapData: (item) => {
            return item;
        },
        maxItemCount: 100,
    });

    const renderTransfer = () => {
        
        const {id} = useParams();
        const url = `${API_URL}/invoices/${id}/transactions`;
        const { data } = useCustom<{data: any;}>({ url, method: "get" });
        let items : any = data?.data;
        // items = items?.map((item : any) => { return { label: `${item.createdAt}`, children: `${item.type}` }})
        console.log(items);
        return (            
            <List
            headerProps={{ extra: <></>, }}
            title={
                <Text style={{ fontSize: 18, fontWeight: 800 }}>
                    Histórico
                </Text>
            }
            breadcrumb={<></>}
            >
                <Card>
                    <Row>
                        <Col xl={24} lg={24} md={24}>
                            <Timeline mode="left" items={items} />
                        </Col>
                    </Row>
                </Card>
            </List>
        );
    }

    const renderOrderSteps = () => {

        return (
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={t("invoices.fields.id")}
                subTitle={`#${record?.id ?? ""}`}
                extra={[
                    <>
                    <RefreshButton
                        resource="invoices"
                        recordItemId={record?.id}
                    />                    
                    </>
                ]}
            >

            </PageHeader>
        );
    };

    const renderCustomerInfo = () => (
        <Card>
            <Row>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Customer>
                        <CustomerInfoText>
                            <Text style={{ fontSize: 14, color: '#888' }}>TRANSAÇÃO</Text>
                            <Text style={{ fontSize: 22, fontWeight: 800,}}>
                                #{record?.id}
                            </Text>
                        </CustomerInfoText>
                    </Customer>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Customer>
                        <CustomerInfoText>
                            <Text style={{ fontSize: 14, color: '#888' }}>PAGADOR</Text>
                            <Text style={{ fontSize: 22, fontWeight: 800,}}>
                                {record?.customer.name}
                                {(record?.customer.name!==record?.customer.razaoSocial) && (
                                    <Text style={{ fontSize: 16, fontWeight: 600,}}>
                                        <br/>
                                        {record?.customer.razaoSocial}
                                    </Text>
                                )}
                            </Text>
                        </CustomerInfoText>
                    </Customer>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Customer>
                        <CustomerInfoText>
                            <Text style={{ fontSize: 14, color: '#888' }}>VALOR BRUTO</Text>
                            <NumberField
                                style={{ fontSize: 22, fontWeight: 800, color: "#52c41a"}}
                                options={{
                                    currency: "BRL",
                                    style: "currency",
                                }}
                                locale="pt-BR" 
                                value={total}
                            />
                        </CustomerInfoText>
                    </Customer>
                </Col>                
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Customer>
                        <CustomerInfoText>
                        <Text style={{ fontSize: 14, color: '#888' }}>STATUS</Text>                        
                            <Text style={{ fontSize: 22, fontWeight: 800,}}>
                                {/* 
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore */}
                                <InvoiceStatus status={statusText} />
                                {/* {record?.statusText} */}
                            </Text>
                        </CustomerInfoText>
                    </Customer>                    
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Customer>
                        <CustomerInfoText>
                            <Text style={{ fontSize: 14, color: '#888' }}>FORMA PAGAMENTO</Text>
                            <Text style={{ fontSize: 18, fontWeight: 400,}}>
                                {record?.type}
                            </Text>
                            <Text style={{ fontSize: 14 }}>
                                (Vencimento: {record?.expireAt})
                            </Text>
                        </CustomerInfoText>
                    </Customer>
                </Col>
                <Col xl={6} lg={6} md={12}>
                    <Customer>
                        <CustomerInfoText>
                            <Text style={{ fontSize: 14, color: '#888' }}>CÓDIGO</Text>
                            {/* <Input value={record?.paymentCode}/> */}
                            {record?.paymentUrl && (
                                <Link to={record?.paymentUrl}>
                                    {(statusText==="Cancelado") ? (
                                        <><span style={{color: "red"}}>⚠ Cobrança vencida ou cancelada</span></>
                                    ): (
                                        <Paragraph copyable>{record?.paymentCode}</Paragraph>
                                    )}                                    
                                </Link>
                            )}
                            {!record?.paymentUrl && (<>
                                {(statusText==="Cancelado") ? (
                                    <><span style={{color: "red"}}>⚠ Cobrança vencida ou cancelada</span></>
                                ) : (
                                    <Paragraph copyable>{record?.paymentCode}</Paragraph>
                                )} 
                            </>)}
                            {/* <Text style={{ fontSize: 12, fontWeight: 800,}}>
                                {record?.paymentCode}
                            </Text> */}
                        </CustomerInfoText>
                    </Customer>
                </Col>
                {record?.paymentUrl && statusText!=="Cancelado" && record?.type==="boleto" && (
                <Col xl={6} lg={6} md={12}>
                    <Customer>
                        <CustomerInfoText>
                            <Text style={{ fontSize: 14, color: '#888' }}>LINK</Text>
                            {record?.paymentUrl && (
                                <Link to={`${record?.paymentUrl}?format=pdf`} style={{"wordBreak": "break-all"}}>
                                    {record?.paymentUrl}
                                </Link>
                            )} 
                        </CustomerInfoText>
                    </Customer>
                </Col>
                )}
                {record?.paymentImg && statusText!="Cancelado" && (
                <Col xl={6} lg={6} md={12}>
                    <Customer>
                        <CustomerInfoText>
                        <Text style={{ fontSize: 14, color: '#888' }}>*</Text>
                            {/* <Text style={{ fontSize: 14 }}>
                                <a href={record?.paymentUrl} target="_blank">{record?.paymentUrl ?? 'https://'}</a>
                            </Text> */}
                              <Image.PreviewGroup
                                preview={{
                                    onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                }}
                                >
                                    <Image width={record?.type==="pix" ? 100 : '100%'} src={`data:image/png;base64,${record?.paymentImg}` ?? 'https://'} />                                
                                </Image.PreviewGroup>
                        </CustomerInfoText>
                    </Customer>
                </Col>    
                )}            
            </Row>
        </Card>
    );

    const renderSplit = () => (
        <List
            headerProps={{ extra: <></>
            // <ExportButton
            //     onClick={triggerExport}
            //     loading={exportLoading}
            // />
            }}
            title={
                <Text style={{ fontSize: 18, fontWeight: 800 }}>
                    {t("invoices.split.title")}
                </Text>
            }
            breadcrumb={<></>}
        >
            {split?.length && split?.filter((item : any) => item.type!=="platform" && item.status===0)?.length>0 && (
                <Alert
                    message="Após a aprovação do pagamento, as transferências levam cerca de até 3 dias úteis para trasferência completa"
                    // description="Additional description and information about copywriting."
                    type="info"
                    style={{marginBottom: 30}}
                    showIcon/>
            )}
            <Table
                pagination={false}
                dataSource={split}
                style={{overflow: "scroll"}}
                // footer={(_data) => (
                //     <ProductFooter>
                //         <Text>{t("invoices.split.mainTotal")}</Text>
                //         <NumberField
                //                 options={{
                //                     currency: "BRL",
                //                     style: "currency",
                //                 }}
                //                 locale="pt-BR" 
                //                 value={total}
                //         />                       
                //     </ProductFooter>
                // )}                
            >
                <Table.Column
                    defaultSortOrder="descend"
                    // sorter={(a: ISplit, b: ISplit) => a.price - b.price}
                    dataIndex="name"
                    title={t("invoices.split.fields.name")}
                    render={(value,record: any) => (<>
                        <Text style={{ fontWeight: 800 }}>{value}</Text>
                        {(record?.type==="customer") && (<>
                            <br/>
                            <Link to={`/customers/edit/${record?.profileId}`} target="_blank">ver perfil</Link>
                        </>)}                        
                    </>)}                    
                />                
                <Table.Column
                    title={t("invoices.split.fields.account")}
                    dataIndex="account"
                    render={(value,record : any) => {
                        let account = record?.account?.find((item : any) => item?.id===record?.accountId);
                        return (
                        <>
                        <div
                        style={{
                            // display: "flex",
                            minWidth: 125,
                            // justifyContent: "space-between",
                        }}
                        >
                            <Text style={{ fontWeight: 800 }}>
                                {account?.bank}
                            </Text>
                            <br />
                            <Text style={{ fontWeight: 600 }}>
                                {account?.account} {account?.agency ? `(ag: ${account?.agency})` : ``}
                            </Text>
                            <br />
                            {record?.link && (
                            <Text style={{ fontWeight: 600 }}>
                                <a href={record?.link} target="_blank">{record?.link ? "ver comprovante" : ""}</a>
                            </Text>
                            )} 
                        </div>                       
                        </>
                        )
                    } }
                />
                <Table.Column
                    defaultSortOrder="descend"
                    // sorter={(a: ISplit, b: ISplit) => a.price - b.price}
                    dataIndex="statusText"
                    title={t("invoices.split.fields.status")}
                    render={(value,record : any) => {
                        console.log("record",record);
                        if(record?.type!=="platform"){
                            return (
                                <Tag color={(statusText==="Aguardando Pagamento" || statusText==="Cancelado" || value==="Cancelado/Erro") ? 'red' : (value==="Em Trânsito") ? 'orange' : 'green'}>
                                    {statusText==="Aguardando Pagamento" ? "Aguardando Pagamento" : (statusText==="Cancelado" || value==="Cancelado/Erro" ? "Cancelado/Erro" : value)}
                                </Tag>
                            )
                        } else {
                            return (<></>);
                        }
                    }}
                />
                <Table.Column
                    defaultSortOrder="descend"
                    // sorter={(a: ISplit, b: ISplit) => a.price - b.price}
                    dataIndex="amount"
                    title={t("invoices.split.fields.amount")}                    
                    render={(value) => {
                        return (
                            <>
                            <div
                            style={{
                                // display: "flex",
                                minWidth: 75,
                                // justifyContent: "space-between",
                            }}
                            >
                                <NumberField
                                    style={{ fontSize: 14, fontWeight: 600, color: "#52c41a" }}
                                    options={{
                                        currency: "BRL",
                                        style: "currency",
                                    }}
                                    locale="pt-BR" 
                                    value={value}
                                />
                            </div>
                            </>
                        );
                    }}
                />
                {/* <Table.Column
                    defaultSortOrder="descend"
                    sorter={(a: ISplit, b: ISplit) => a.price - b.price}
                    dataIndex="price"
                    title={t("invoices.split.fields.total")}
                    render={(value) => (
                        <Text style={{ fontWeight: 800 }}>{value}</Text>
                    )}
                /> */}
            </Table>
        </List>
    );

    // if(!record){
    //     return (<Skeleton />);
    // }

    return (
        <>
            <Space size={24} direction="vertical" style={{ width: "100%" }}>
                {renderOrderSteps()}
                {renderCustomerInfo()}
            </Space>
            <Card style={{ marginTop: 30 }}>
                {renderSplit()}
            </Card>
            <Card style={{ marginTop: 30 }}>
                {renderTransfer()}
            </Card>
        </>
    );
};
