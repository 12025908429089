import { useMemo, useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { PHONE_CODES, PORTAL_URL, API_URL, TOKEN_KEY } from "../../constants";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import {
    Form,
    Input,
    Avatar,
    Row,
    Col,
    Typography,
    Space,
    Radio,
    Select,
    InputProps,
    Alert,
    InputNumber,
    Divider,
    Card,
    DatePicker,
    DatePickerProps
} from "antd";

import MaskedInput from "antd-mask-input";

import axios from "axios";

const { Text, Paragraph } = Typography;

import { ICustomer } from "interfaces";
import { stringify } from "querystring";

const customFormat: DatePickerProps['format'] = (value) => `${value.format('DD/MM/YYYY')}`;

export const CustomerCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps, queryResult, redirect } = useForm<ICustomer>({
        redirect: false,
        onMutationSuccess: (data, variables, context) => {
            console.log({ data, variables, context });
            console.log("data",data);
            // redirect("show", data?.data?.id);
            redirect("edit", data?.data?.id,{ 'openModal': 'true'});
        }
    });
    const [customerType, setCustomerType] = useState(null);
    const [customerPhone, setCustomerPhone] = useState('');
    const [showForm, setShowForm] = useState(false);    
    const [showCompleteForm, setShowCompleteForm] = useState(false);
    const [phoneMask, setPhoneMask] = useState(null as any);
    const [profileUrl, setProfileUrl] = useState('');
    // const [phoneCodes, setPhoneCodes] = useState([] as any[]);

    const handleTypeChange = (e: any) => {
        setCustomerType(e.target.value);
        onNameChange(e.target.value);
    };

    const phoneCodes = PHONE_CODES?.map(item => { return { label: `${item.name} (${item.code})`, value: item.iso, mask: item.mask}}) ?? [];

    const changePhoneCode = (value: any) => {
        console.log(`selected ${value}`);
        let findMask = phoneCodes?.find(item => item.value===value)?.mask;
        if(Array.isArray(findMask)){
            let mask =  findMask[findMask.length - 1]; // findMask.map((item: any) => { return { mask: item.replaceAll("9","0"), lazy: true } });
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0"));
        } else {            
            let mask = findMask; //Array.isArray(findMask) && findMask?.length ? findMask[findMask.length - 1] : findMask; // pega último mask por padrão
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0") || "(00) 00000-0000");
        }        
    };

    const onNameChange = (value: any) => {

        const type = formProps.form?.getFieldValue("type");
        const name = formProps.form?.getFieldValue("name");
        if(type==="individual" && name){
            formProps.form?.setFields([
                {
                    name: "razaoSocial", // required
                    value: name
                },
            ]);
        }        
    }

    const onChangeCompleteForm = (value: any) => {
        console.log(value);
        if(["receiver","all"].includes(value?.target?.value)){
            setShowCompleteForm(true);
        } else if(value?.target?.value==="payer"){
            setShowCompleteForm(false);
        }
    }

    const currencyParser = (value : any) => {
        // Remove non-digit characters
        const numericValue = value.replace(/[^\d]/g, '');
      
        // If the input is empty or only contains non-digit characters, return 0
        if (!numericValue) {
            return 0;
        }

        // Get the last two digits as decimals
        const decimals = numericValue.slice(-2);
      
        // Get the integer part excluding the last two digits
        const integerValue = numericValue.slice(0, -2) || '0';
      
        // Concatenate the integer and decimal parts
        const formattedValue = integerValue + '.' + decimals;
      
        // Parse the formatted value to a float
        const parsedValue = parseFloat(formattedValue);
      
        // Return the parsed numeric value
        return isNaN(parsedValue) ? 0 : parsedValue;
    };   
                              
    const currencyFormatter = (value : any) => {
        return new Intl.NumberFormat("pt-br", {
          style: "currency",
          currency: "BRL"
        }).format(value);
    };

    const findCpfCnpj = async(e: any) => {
        let value = e.target.value; //?.replace(/\D/g,'');
        let type = formProps.form?.getFieldValue("type");
        console.log(value);
    
        if(
          (type==="individual" && value?.replace(/\D/g,'')?.length>=11)
          ||
          (type==="corporation" && value?.replace(/\D/g,'')?.length>=14)
        ){
    
          let response: any = await axios.post(`${API_URL}/cpfCnpj`, {
            createIfExist: true,
            type: "customers",
            payerOrReceiver: "all",
            entityType: type,
            cpfCnpj: value,
          },{
            headers:{
                'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                'companyId': localStorage.getItem("companyId") ?? '0'
            }
          });
          console.log(response);

          if(response?.data?.profileId){
            setShowForm(false);
            setShowCompleteForm(false);
            alert("Atenção! Este CPF/CNPJ já possui cadastro no SplitFácil! Veja os detalhes no link redirecionado a seguir.");
            setProfileUrl(`${PORTAL_URL}/${customerUrl}/edit/${response?.data?.profileId}`);
            window.location.href = `/${customerUrl}/edit/${response?.data?.profileId}`
          } else {
            setShowForm(true);
            if(response?.data?.razaoSocial){
                let result = response?.data;
                formProps.form?.setFields([
                {
                    name: `name`,
                    value: result?.fantasia
                },
                {
                    name: `razaoSocial`,
                    value: result?.razaoSocial,
                },
                {
                    name: ['address','zipCode'],
                    value: `${result?.endereco?.cep?.replace(".","")?.replace("-","")?.slice(0, -3)}-${result?.endereco?.cep?.replace(".","")?.replace("-","")?.slice(-3)}`
                },
                {
                    name: ['address','street'],
                    value: result?.endereco?.logradouro
                },
                {
                    name: ['address','number'],
                    value: result?.endereco?.numero
                },
                {
                    name: ['address','complement'],
                    value: result?.endereco?.complemento
                },
                {
                    name: ['address','neighborhood'],
                    value: result?.endereco?.bairro
                },
                {
                    name: ['address','city'],
                    value: result?.endereco?.municipio
                },
                {
                    name: ['address','state'],
                    value: result?.endereco?.uf
                },
                ]);
            }            
          }
        }
      };

    const findCep = async(e: any, type : string) => {
        let value = e.target.value?.replace(/\D/g,'');
        console.log(value);
    
        if(value.length>=8){
          let response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
          console.log(response);
          if(response?.data){
            let cep = response?.data;
            formProps.form?.setFields([
              {
                name: ['address','street'],
                value: cep.logradouro
              },
              {
                name: ['address','neighborhood'],
                value: cep.bairro
              },
              {
                name: ['address','city'],
                value: cep.localidade
              },
              {
                name: ['address','state'],
                value: cep.uf
              },
            ]);
          }
        }
    }  

    const Address = (type : string) => {
        const states = [
            { value: "AC", label: "Acre" },
            { value: "AL", label: "Alagoas" },
            { value: "AP", label: "Amapá" },
            { value: "AM", label: "Amazonas" },
            { value: "BA", label: "Bahia" },
            { value: "CE", label: "Ceará" },
            { value: "DF", label: "Distrito Federal" },
            { value: "ES", label: "Espírito Santo" },
            { value: "GO", label: "Goiás" },
            { value: "MA", label: "Maranhão" },
            { value: "MT", label: "Mato Grosso" },
            { value: "MS", label: "Mato Grosso do Sul" },
            { value: "MG", label: "Minas Gerais" },
            { value: "PA", label: "Pará" },
            { value: "PB", label: "Paraíba" },
            { value: "PR", label: "Paraná" },
            { value: "PE", label: "Pernambuco" },
            { value: "PI", label: "Piauí" },
            { value: "RJ", label: "Rio de Janeiro" },
            { value: "RN", label: "Rio Grande do Norte" },
            { value: "RS", label: "Rio Grande do Sul" },
            { value: "RO", label: "Rondônia" },
            { value: "RR", label: "Roraima" },
            { value: "SC", label: "Santa Catarina" },
            { value: "SP", label: "São Paulo" },
            { value: "SE", label: "Sergipe" },
            { value: "TO", label: "Tocantins" }
        ];
        return (<>
            <Divider orientation="left">{type==="corporation" ? `Endereço Empresarial` : `Endereço Residencial`}</Divider>
            <Form.Item
              name={['address','zipCode']}
              label={t("customers.fields.zipCode")}
              rules={[
                { required: true },
              ]}
            >
              <MaskedInput
                size="large"
                mask={'00000-000'}
                placeholder={"ex: 04040-000"}
                onChange={(value) => findCep(value,type)}
              />
            </Form.Item>
            <Form.Item
              // name="address"
              name={['address','street']}
              label={t("customers.fields.address")}
              rules={[
                { required: true },
              ]}
            >
              <Input
                size="large"
                placeholder={"ex: Av Paulista"}
              />
            </Form.Item> 
            <Form.Item
              // name="addressNumber"
              name={['address','number']}
              label={t("customers.fields.addressNumber")}
              rules={[
                { required: true },
              ]}
            >
              <Input
                size="large"
                placeholder={"ex: 100"}
              />
            </Form.Item> 
            <Form.Item
              // name="complement"
              name={['address','complement']}
              label={t("customers.fields.complement")}
              rules={[
                { required: false },
              ]}
            >
              <Input
                size="large"
                placeholder={"ex: Ap 13"}
              />
            </Form.Item> 
            <Form.Item
              // name="neighborhood"
              name={['address','neighborhood']}
              label={t("customers.fields.neighborhood")}
              rules={[
                { required: true },
              ]}
            >
              <Input
                size="large"
                placeholder={"ex: Centro"}
              />
            </Form.Item> 
            <Form.Item
              // name="city"
              name={['address','city']}
              label={t("customers.fields.city")}
              rules={[
                { required: true },
              ]}
            >
              <Input
                size="large"
                placeholder={"ex: São Paulo"}
              />
            </Form.Item> 
            <Form.Item
                name={['address','state']}
                label={t("customers.fields.state")}
                rules={[
                  { required: true },
                ]}
            >
                <Select                            
                    allowClear
                    options={states}
                    placeholder={t("customers.fields.state")}
                />
            </Form.Item>
        </>);
    }

    const location = useLocation();
    const currentPath = location.pathname;
    const customerView = currentPath?.includes("payers") ? "Pagador" : "Recebedor";
    const customerUrl = currentPath?.includes("payers") ? "payers" : "customers";

    return (<>        
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
            title={`Criar Novo ${customerView}`}
        >            
            <Card title={`Opção 1: Copie e compartilhe o link abaixo para que o seu próprio parceiro realize o cadastro:`}>
                {/* <Text style={{fontWeight: "bold"}}></Text>                 */}
                <Paragraph copyable style={{backgroundColor: "rgb(230, 244, 255)", padding: "12.5px", marginTop: 10, borderRadius: 5}}>                    
                    <a href={`${PORTAL_URL}/register/${customerUrl}/${localStorage.getItem("companyHash")}`} target="_blank">{`${PORTAL_URL}/register/${customerUrl}/${localStorage.getItem("companyHash")}`}</a>
                </Paragraph>
            </Card>
    
            <Divider orientation="center">... OU ...</Divider>

            <Card title={`Opção 2: Preencha os dados abaixo:`}>
                <Form
                    {...formProps}
                    layout="vertical"
                    initialValues={{
                        // type: "individual",
                        countryCode: "BR",
                        active: true,
                        phone: null,
                        additionalInformation: {
                            birthDate: "",            
                            monthlyIncome: 0
                        },  
                        address: {
                            zipCode: "",
                            street: "",
                            number: "",
                            complement: "",
                            neighborhood: "",
                            city: "",
                            state: "",
                        }
                        // payerOrReceiver: "all"
                    }}
                >
                    <Row gutter={[64, 0]} wrap>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label={t("customers.fields.type.title")}
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Radio.Group onChange={handleTypeChange}>
                                    <Radio value="individual">{t("customers.fields.type.individual")}</Radio><br/>
                                    <Radio value="corporation">{t("customers.fields.type.corporation")}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {(formProps.form?.getFieldValue("type") || showForm) ? (
                            <Form.Item
                                label={t("customers.fields.cpfCnpj")}
                                name="cpfCnpj"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <MaskedInput
                                    disabled={false}
                                    onChange={findCpfCnpj}
                                    mask={customerType==="individual" ? '000.000.000-00' : '00.000.000/0000-00'}
                                >
                                        {/* 
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore */}
                                        {/* {(props: InputProps) => <Input disabled={false} {...props} />} */}
                                </MaskedInput>
                            </Form.Item>  
                            ) : <></>}
                            {(showForm) ? (<>
                            <Form.Item
                                label={t("customers.fields.name")}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}                            
                            >
                                <Input onChange={onNameChange}/>
                            </Form.Item>
                            <Form.Item
                                label={t("customers.fields.razaoSocial")}
                                name="razaoSocial"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}                            
                            >
                                <Input disabled={formProps.form?.getFieldValue("type")==="individual" ? true : false}/>
                            </Form.Item>   
                            <Form.Item
                                label={t("customers.fields.payerOrReceiver.title")}
                                name="payerOrReceiver"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Radio.Group onChange={onChangeCompleteForm}>
                                    <Radio value="payer">{t("customers.fields.payerOrReceiver.payer")}</Radio><br/>
                                    <Radio value="receiver">{t("customers.fields.payerOrReceiver.receiver")}</Radio><br/>
                                    <Radio value="all">{t("customers.fields.payerOrReceiver.all")}</Radio>
                                </Radio.Group>
                            </Form.Item>                                                               
                            <Form.Item
                                label={t("customers.fields.email")}
                                name="email"
                                rules={[
                                    {
                                        required: false,
                                        type: "email",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Space.Compact style={{ width: '100%' }}>
                            <Form.Item
                                label={"País"}
                                name="countryCode"
                                style={{ width: '40%' }}
                            >
                                <Select
                                    showSearch
                                    // defaultValue="BR"
                                    options={phoneCodes}
                                    onChange={changePhoneCode}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label={t("customers.fields.phone")}
                                name="phone"
                                style={{ width: '60%' }}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <MaskedInput
                                        disabled={false}
                                        mask={phoneMask || "(00) 00000-0000"}
                                        >
                                        {/* 
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore */}
                                        {/* {(props: InputProps) => <Input {...props} />} */}
                                </MaskedInput>                            
                            </Form.Item>
                            </Space.Compact>  
                            {(["receiver","all"].includes(formProps.form?.getFieldValue("payerOrReceiver")) || showCompleteForm) ? (<>
                            <Form.Item
                                label={t("customers.fields.birthDate")}
                                name={['additionalInformation','birthDate']}
                                rules={[
                                        {
                                            required: customerType==="corporation" ? false : true
                                        },
                                    ]}
                                hidden={customerType==="corporation" ? true : false}
                                >
                                <DatePicker
                                    // locale={locale}
                                    size="large"
                                    format={customFormat}
                                    // onChange={updateSplit}
                                    // onOk={updateSplit}
                                    // disabledDate={disabledDate}
                                    placeholder="DD/MM/AAAA"
                                    showToday={false}                                    
                                    style={{ width: "100%" }}
                                    />
                            </Form.Item> 
                            <Form.Item
                                label={`Renda Mensal / Faturamento Mensal (R$)`}
                                name={['additionalInformation','monthlyIncome']}
                                rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                        // ({ getFieldValue }) => ({
                                            validator(_, value) {
                                            console.log(value);
                                            if (!value || Number(value)===0) {
                                                return Promise.reject('Oops! Insira um valor válido');
                                            }
                                            return Promise.resolve();
                                            },
                                        // }),
                                        }
                                    ]}      
                                tooltip={"Informe sua renda/faturamento média mensal (R$), essa informação se manterá em sigilo."}
                                >
                                    <InputNumber
                                        size="large"
                                        formatter={currencyFormatter}
                                        parser={currencyParser}
                                        // onBlur={currencyParser}
                                        value={0}
                                        style={{ width: '100%' }} 
                                    /> 
                            </Form.Item>
                            {Address(formProps.form?.getFieldValue("customerType"))}
                            </>) : (<></>)}
                            </>) : (<>
                                {(profileUrl) && (<a href={profileUrl}>Editar cadastro</a>)}
                                <Alert
                                    message="Preencha o CPF/CNPJ para continuar com o cadastro"
                                    // description="Additional description and information about copywriting."
                                    type="error"
                                    style={{margin: '15px auto'}}
                                    showIcon
                                />
                            </>)}
                        </Col>
                    </Row>
                    <Alert
                        message="Obs: Todos os usuários devem ter apenas um cadastro único no sistema, fazemos a verifição pelo CPF/CNPJ"
                        // description="Additional description and information about copywriting."
                        type="info"
                        style={{margin: '15px auto'}}
                        showIcon
                    />
                </Form>
            </Card>
        </Create>
        </>);
};
