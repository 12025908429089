import { useMemo, useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

import { TOKEN_KEY, API_URL } from "./../../constants";
import {
    useTranslate,
    IResourceComponentsProps,
    useNavigation,
    useShow,
    CrudFilters,
    HttpError,
    useExport,
    useCustom,
    useUpdateMany,
    file2Base64,
} from "@refinedev/core";

import {
    List,
    useTable,
    useEditableTable,
    DateField,
    BooleanField,
    useModal,
    ShowButton,
    EditButton,    
    useImport,
    ImportButton,
    ExportButton,
    RefreshButton,
    CreateButton,
    getValueFromEvent
} from "@refinedev/antd";
import { FormOutlined, SearchOutlined, MoreOutlined, WarningOutlined, UploadOutlined, DownloadOutlined, ReloadOutlined, UnorderedListOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tag, Table, Dropdown, Menu, Avatar, Row, Col, Card, FormProps, Button, DatePicker, Form, Input, Select, Space, Upload, Typography, Descriptions, Spin, Modal, Switch } from "antd";
import { InvoiceStatus, AccountStatus } from "components";
import type { UploadProps } from 'antd';
import { Alert } from 'antd';
import { ICustomer, ICustomerFilterVariables, ICustomerFile } from "interfaces";
import { render } from "@testing-library/react";
const { Text } = Typography;
import "./../../css/main.css";

export const CustomerList: React.FC<IResourceComponentsProps> = () => {
    // const { tableProps } = useTable<ICustomer>();
    const { tableProps,
        formProps,
        searchFormProps,
        isEditing,
        saveButtonProps,
        cancelButtonProps,
        setId: setEditId,
    } = useEditableTable<
        ICustomer,
        HttpError,
        ICustomerFilterVariables
    >({
        pagination: {
            pageSize: 20,
        },
        initialSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        onSearch: (params : any) => {
            const filters: CrudFilters = [];
            const { q, status, createdAt, active } = params;

            filters.push({
                field: "q",
                operator: "eq",
                value: q,
            });

            filters.push(
                {
                    field: "createdAt",
                    operator: "gte",
                    value: createdAt
                        ? createdAt[0].startOf("day").toISOString()
                        : undefined,
                },
                {
                    field: "createdAt",
                    operator: "lte",
                    value: createdAt
                        ? createdAt[1].endOf("day").toISOString()
                        : undefined,
                },
            );

            // filters.push({
            //     field: "payerOrReceiver",
            //     operator: "eq",
            //     value: payerOrReceiver,
            // });

            filters.push({
                field: "active",
                operator: "eq",
                value: active,
            });

            filters.push({
                field: "status",
                operator: "eq",
                value: status,
            });

            return filters;
        },
        syncWithLocation: false,
    });
    const { edit, show } = useNavigation();
    const { modalProps } = useModal();
    const [showImport, setShowImport] = useState(false);
    const [resultImport, setResultImport] = useState([]);
    const [sheetsUrl, setSheetsUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [isModalSheetsOpen, setIsModalSheetsOpen] = useState(false);
    const [isModalPdfOpen, setIsModalPdfOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    // const [customerView, setCustomerView] = useState("");

    const t = useTranslate();

    const { queryResult, setShowId } = useShow<ICustomer>();
    
    const { data: showQueryResult } = queryResult;
    const record = showQueryResult?.data;    

    const TrueIcon = () => <span>✅</span>;

    const FalseIcon = () => <span>❌</span>;

    const rowClassName = (record : any) => {
        return record.active ? '' : 'row-disabled';
    };

    const importProps = useImport<ICustomerFile>({
        batchSize: 1,
        mapData: (item) => {
            // console.log(item);
            if(!item?.["Nome Completo"]?.includes("Exemplo") && item?.["Nome Completo"] && item["Nro do CPF ou CNPJ"]){
                return {
                    name: item["Nome Completo"],
                    razaoSocial: item["Razão Social"],
                    type: item["Pessoa Física ou Pessoa Jurídica (PF/PJ)?"]?.replace("Pessoa Física (CPF)","individual")?.replace("Pessoa Jurídica (CNPJ)","corporation"),
                    cpfCnpj: item["Nro do CPF ou CNPJ"],
                    email: item["Email"],
                    countryCode: item["País Celular"]?.replace("BR (+55)","BR")?.replace("US (+1)","US"),
                    phone: item["Nro Celular"],
                    payerOrReceiver: item["Tipo de Cliente"]?.replace("Apenas Pagador","payer")?.replace("Apenas Recebedor","receiver")?.replace("Pagador e Recebedor","all")
                };
            }
        },
        onFinish: (result) => {
            // console.log(result);

            // success requests response
            // let successNotification : any = [];
            result.succeeded.forEach((item : any) => {
                console.log(item);
                // successNotification.push(`${item?.["Nome Completo"]} importado com sucesso!`)
            });
            // if(successNotification?.length) window.alert(successNotification?.join("\n"));

            // failed requests response
            let errorNotification : any = [];
            for (let item of result.errored) {
                console.log(item);
                errorNotification.push(`Error: ${item?.response?.[0]?.response?.data}`)
                window.alert(`Error: ${item?.response?.[0]?.response?.data}`);
            }
            // result.errored.forEach((item : any) => {
            //     console.log(item);
            //     errorNotification.push(`Error: ${item?.response?.[0]?.response?.data}`)
            // });
            // if(errorNotification?.length) window.alert(errorNotification?.join("\n"));
        },
    });

    const moreMenu = (id: number) => (
        <Menu mode="vertical">
            <Menu.Item
                key="1"
                style={{
                    fontSize: 15,
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{ color: "green", fontSize: "15px" }}
                    />
                }
                onClick={() => edit("customers", id)}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="2"
                style={{
                    fontSize: 15,
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{ color: "green", fontSize: "15px" }}
                    />
                }
                onClick={() => show("customers", id)}
            >
                {t("buttons.show")}
            </Menu.Item>
        </Menu>
    );

    const generateSheets = () => {
        setLoading(true);
        axios.get(`${API_URL}/sheets`,{
            headers:{
                'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                'companyId': localStorage.getItem("companyId") ?? '0'
            }
        })
        .then((response : any) => {
            console.log(response.data);
            const result = response.data;
            setSheetsUrl(result ?? "");
            setLoading(false);
            window.location.href = result;
        });
    }

    const sheetsProps: UploadProps = {
        name: 'file',
        maxCount: 1,
        accept: ".csv",
        action: `${API_URL}/customers/upload/sheets`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
            'companyId': localStorage.getItem("companyId") ?? '0'
        },
        beforeUpload: (file) => {
            setShowImport(false);
            setResultImport([]);
        },
        onChange(info) {
            
            // window.location.href = '/invoices/create';
            console.log("importt");
            console.log(info);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                console.log(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                console.log(`${info.file.name} file upload failed.`);
            }

            if(info?.fileList?.[0]?.response){
                setShowImport(true);
                handleCancel();
                setResultImport(info?.fileList?.[0]?.response);
            }

        },
    };    

    const pdfProps: UploadProps = {
        name: 'file',
        maxCount: 1,
        accept: ".pdf",
        action: `${API_URL}/customers/upload/pdf`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
            'companyId': localStorage.getItem("companyId") ?? '0'
        },
        beforeUpload: async(file) => {
            return new Promise(async (resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                console.log(file);
                reader.onload = () => {
                  // Attach the base64 string to the file object
                  // @ts-ignore
                  file.base64String = reader.result?.replace(/^.*,/, '');
                  setShowImport(false);
                  setResultImport([]);
                  resolve(file);
                };
          
                reader.onerror = (error) => {
                  console.error('Error converting file to base64:', error);
                  reject(error);
                };
            });            
        },        
        customRequest: async ({ onSuccess, onError, file }) => {
            try {
                // @ts-ignore
              const response = await fetch(`${API_URL}/customers/upload/pdf`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json', // Specify JSON content type
                  'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                  'companyId': localStorage.getItem('companyId') ?? '0',
                },
                body: JSON.stringify({ file }), // Include base64 string in the payload
              });
        
              const data = await response.json();
              console.log(data);

              if(data?.success){
                setShowImport(true);
                handleCancel();
                setResultImport(data?.result);
              }              

            } catch (error) {
              console.error('Error uploading file:', error);
            }
        },
        onChange(info) {
            
            // window.location.href = '/invoices/create';
            console.log("importt");
            console.log(info);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                console.log(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                console.log(`${info.file.name} file upload failed.`);
            }

        },
    };    

    const showModalSheets = () => {
      setIsModalSheetsOpen(true);
    };

    const showModalPdf = () => {
        setIsModalPdfOpen(true);
    }
  
    const handleCancel = () => {
      setIsModalSheetsOpen(false);
      setIsModalPdfOpen(false);
    };

    const loader = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);            
    };

    const renderUpload = () => (
        <Card title="Leia as instruções:" style={{margin: "15px auto"}}>
            <Spin spinning={loading}>
                <Row>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                        <Text style={{ fontSize: 14, fontWeight: 800}}>Baixe a Planilha</Text><br/>
                        <Text style={{ fontSize: 14, fontWeight: 400}}>1. Faça o download da planilha de modelo (botão abaixo)</Text><br/>
                        <Text style={{ fontSize: 14, fontWeight: 400}}>2. Preencha com os dados dos clientes</Text>
                        
                        <br/><br/>

                        {(!sheetsUrl) && (
                        <Button type="primary" icon={<DownloadOutlined />} style={{ marginBottom: 15 }} onClick={() => generateSheets()}>
                            Download Modelo de Importação
                        </Button>
                        )}
                        {(sheetsUrl) && (
                        <Button type="primary"  icon={<DownloadOutlined />} style={{ marginBottom: 15 }}>
                            <Link to={sheetsUrl} target="_blank">Download Modelo de Importação por Planilha</Link>
                        </Button>
                        )}
                        {(sheetsUrl) && (<><br/><br/><Text style={{ fontSize: 14, fontWeight: 800}}>Link da planilha:</Text><br/><Link to={sheetsUrl} target="_blank">{sheetsUrl}</Link></>)}

                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>

                        <Text style={{ fontSize: 14, fontWeight: 800}}>Importe a Planilha Preenchida</Text><br/>
                        <Text style={{ fontSize: 14, fontWeight: 400}}>1. Salve o arquivo em formato ".csv"</Text><br/>
                        <Text style={{ fontSize: 14, fontWeight: 400}}>2. Faça a importação do arquivo  (botão abaixo)</Text><br/>
                        <Text style={{ fontSize: 14, fontWeight: 400}}>3. Veja o resultado da importação em seguida</Text><br/><br/>

                        <Upload
                            {...sheetsProps}
                            style={{marginBottom: 15}}
                        >
                            <Button type="primary" icon={<UploadOutlined />}>Importar Planilha Preenchida</Button>
                        </Upload>
                    </Col>
                </Row>
                <Row style={{marginTop:30}}>
                    <Col xl={18} lg={18} md={24} sm={24} xs={24}>
                        <Text style={{ fontSize: 14, fontWeight: 800}}>Ficou com dúvidas? Veja o vídeo:</Text><br/><br/>
                        <iframe width="100%" height="350px" allowFullScreen src="https://www.youtube.com/embed/U3IT4uQ2jsg?si=j2c-hYN8PPjAVo_x" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    </Col>
                </Row>
            </Spin>
        </Card>
    );

    const renderPdf = () => (
        <Card title="Leia as instruções:" style={{margin: "15px auto"}}>
            <Spin spinning={loading}>
                <Row>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                        <Text style={{ fontSize: 14, fontWeight: 800}}>Baixe o PDF</Text><br/>
                        <Text style={{ fontSize: 14, fontWeight: 400}}>1. Faça o download do arquivo PDF no seu banco</Text><br/>
                        {/* <Text style={{ fontSize: 14, fontWeight: 400}}>2. Importe o arquivo</Text> */}                        
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>

                        <Text style={{ fontSize: 14, fontWeight: 800}}>Importe o PDF</Text><br/>                        
                        <Upload
                            {...pdfProps}
                            style={{marginBottom: 15}}
                        >
                            <Button type="primary" icon={<UploadOutlined />}>Importar PDF</Button>
                        </Upload>
                    </Col>
                </Row>
                {/* <Row style={{marginTop:30}}>
                    <Col xl={18} lg={18} md={24} sm={24} xs={24}>
                        <Text style={{ fontSize: 14, fontWeight: 800}}>Ficou com dúvidas? Veja o vídeo:</Text><br/><br/>
                        <iframe width="100%" height="350px" allowFullScreen src="https://www.youtube.com/embed/U3IT4uQ2jsg?si=j2c-hYN8PPjAVo_x" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    </Col>
                </Row> */}
            </Spin>
        </Card>
    );

    // const { triggerExport, isLoading: exportLoading } = useExport<any>({
    //     mapData: (item) => {
    //         if(!item?.customer?.success || !item?.account?.success){
    //             return item;
    //         }
    //     },
    //     // maxItemCount: 100,
    // });

    const { mutate, isLoading } = useUpdateMany<ICustomer>();

    const onSelectChange = (selectedRowKeys: any) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const updateSelectedItems = (active: true | false) => {
        mutate(
            {
                resource: "customers",
                ids: selectedRowKeys.map(String),
                values: {
                    active,
                },
                mutationMode: "undoable",
            },
            {
                onSuccess: () => {
                    setSelectedRowKeys([]);
                },
            },
        );
    };

    const hasSelected = selectedRowKeys.length > 0;
    const location = useLocation();
    const currentPath = location.pathname;
    const customerView = currentPath?.includes("payers") ? "Pagadores" : "Recebedores";

    return (
        <>
        {(!showImport && (
            <>                
                <Row gutter={[16, 16]}>
                    <Col
                        xl={6}
                        lg={24}
                        xs={24}
                        style={{
                            marginTop: "48px",
                        }}
                    >
                        <Card title={t("users.filter.title")}>
                            <Filter formProps={searchFormProps} customerView={customerView} />
                        </Card>
                    </Col>
                    <Col xl={18} xs={24}>
                        {/* {renderUpload()} */}
                        <List
                            title={
                                <Text style={{ fontSize: 22, fontWeight: 800 }}>
                                    {customerView}
                                </Text>
                            }
                            headerProps={{
                                extra: ["manager","admin","owner","master"].includes(localStorage.getItem("role") ?? "") && (
                                    <Space>
                                        <CreateButton>Inserir {customerView?.replace("es","")}</CreateButton>
                                        {/* <Button type="primary" icon={<UploadOutlined />} onClick={showModalSheets}>Importar {customerView} por Planilha</Button> */}
                                        {/* <Button type="primary" icon={<UploadOutlined />} onClick={showModalPdf}>Importar Clientes por PDF</Button> */}
                                        {/* <ImportButton {...importProps} /> */}
                                        {/* <RefreshButton resource="customers"/> */}
                                        {/* <ExportButton
                                            onClick={triggerExport}
                                            loading={exportLoading}
                                        /> */}
                                    </Space>
                                ),
                                subTitle: hasSelected && (
                                    <Space style={{ gap: 0, marginLeft: "1em", backgroundColor: "#fafafa", borderColor: "#fafafa" }}>
                                         {selectedRowKeys.length} Clientes: 
                                        <Button
                                            type="text"
                                            onClick={() => updateSelectedItems(true)}
                                            loading={isLoading}
                                            style={{ color: "green" }}
                                            icon={
                                                <CheckCircleOutlined                                                    
                                                />
                                            }
                                        >
                                            Ativar todos
                                        </Button>
                                        <Button
                                            type="text"
                                            onClick={() => updateSelectedItems(false)}
                                            loading={isLoading}
                                            style={{ color: "red" }}
                                            icon={
                                                <CloseCircleOutlined />
                                            }
                                        >
                                            Inativar todos
                                        </Button>
                                    </Space>
                                )
                            }}>
                            <Form {...formProps}>                            
                            <Table
                                {...tableProps}
                                rowSelection={rowSelection}
                                rowKey="id"
                                onRow={(record) => ({
                                    // eslint-disable-next-line
                                    onClick: (event: any) => {
                                        // if (event.target.nodeName === "TD") {
                                            setEditId && setEditId(record.id);
                                        // }
                                    },
                                })}
                                rowClassName={rowClassName}
                            >
                                {/* <Table.Column
                                    key="image"
                                    align="center"
                                    render={() => (
                                        <Avatar
                                            src="/images/default-store-img.png"
                                            alt="Default Customer Image"
                                            size={64}
                                        />
                                    )}
                                /> */}
                                <Table.Column
                                    dataIndex="id"
                                    align="center"
                                    title={t("customers.fields.id")}
                                />
                                <Table.Column
                                    dataIndex="name"
                                    title={`${t("customers.fields.name")}`}
                                    render={(value,record : any) =>  {
                                        return (<>
                                        {(record.status===1) ? (
                                            <Link to={`/customers/edit/${record.id}`} target="_blank">{value}</Link>
                                        ) : (
                                            <Link to={`/register/customers/${localStorage.getItem("companyHash")}?cpfCnpj=${record.cpfCnpj}`} target="_blank">{value}</Link>
                                        )}                                        
                                        </>)}
                                    }
                                />
                                {/* <Table.Column
                                    dataIndex="email"
                                    title={t("customers.fields.email")}
                                /> */}
                                {/* <Table.Column
                                    dataIndex="phone"
                                    title={t("customers.fields.phone")}
                                /> */}
                                <Table.Column
                                    dataIndex="razaoSocial"
                                    title={t("customers.fields.razaoSocial")}
                                />
                                {customerView==="Recebedores" && (
                                <Table.Column
                                    dataIndex="statusText"
                                    title={t("customers.fields.status")}
                                    render={(value,record : any) =>  { return (<>
                                        <AccountStatus status={value} />
                                        </>)}
                                    }
                                />
                                )}
                                {/* <Table.Column
                                    dataIndex="accountQty"
                                    title={t("customers.fields.accountQty")}
                                    render={(value,record : any) => <Tag color={value>0 ? "green" : "red"}>{["receiver","all"].includes(record?.payerOrReceiver) && value===0 && (<WarningOutlined/>)} {value} contas</Tag> }
                                /> */}
                                {/* <Table.Column
                                    dataIndex="cpfCnpj"
                                    title={t("customers.fields.cpfCnpj")}
                                /> */}
                                <Table.Column
                                    dataIndex="payerOrReceiverText"
                                    title={t("customers.fields.payerOrReceiver.title")}
                                />
                                {/* <Table.Column
                                    dataIndex="active"
                                    title={t("customers.fields.active.title")}
                                    align="center"
                                    render={(value) => <BooleanField 
                                        trueIcon={<TrueIcon />}
                                        falseIcon={<FalseIcon />}
                                        value={value} />}
                                /> */}
                                <Table.Column
                                key="active"
                                dataIndex="active"
                                title={`${customerView?.replace("es","")} Ativo?`}
                                // render={(value) => <BooleanField value={value} />}
                                render={(value, data: ICustomer) => {                                    
                                    if (isEditing(data.id)) {
                                        return (
                                            <Button {...saveButtonProps} style={{ padding: 0, border: 'none', background: 'transparent' }}>
                                            <Form.Item
                                                name="active"                                                
                                                valuePropName="checked"
                                            >
                                                {(loading) && (<Spin spinning={loading}/>)}
                                                <Switch checkedChildren="SIM" unCheckedChildren="NÃO" onChange={loader}/>
                                            </Form.Item>
                                            </Button>
                                        );
                                    }
                                    return <Switch onClick={() => { loader(); setEditId(data.id); }} checked={value} disabled={true} style={{ 'pointerEvents': 'none'}} checkedChildren="SIM" unCheckedChildren="NÃO"/>
                                    // <BooleanField
                                    // value={value}
                                    // trueIcon={<TrueIcon />}
                                    // falseIcon={<FalseIcon />}/>
                                }}
                                />
                                <Table.Column
                                    dataIndex="createdAt"
                                    title={t("customers.fields.createdAt")}
                                    render={(value) => (
                                        <DateField value={value} format="LLL" />
                                    )}
                                    sorter
                                />
                                {/* <Table.Column<ICustomer>
                                    fixed="right"
                                    title={t("table.actions")}
                                    dataIndex="actions"
                                    key="actions"
                                    align="center"
                                    render={(_, record) => (
                                        <Dropdown
                                            overlay={moreMenu(record.id)}
                                            trigger={["click"]}
                                        >
                                            <MoreOutlined
                                                style={{
                                                    fontSize: 24,
                                                }}
                                            />
                                        </Dropdown>
                                    )}
                                /> */}
                                <Table.Column<ICustomer>
                                title="Editar"
                                dataIndex="actions"
                                render={(_, record) => (
                                    <Space>
                                        <EditButton
                                            hideText
                                            size="large"
                                            recordItemId={record.id}
                                        />
                                        {/* <ShowButton
                                            hideText
                                            size="large"
                                            recordItemId={record.id}
                                        /> */}
                                    </Space>
                                )}
                                />
                            </Table>
                            </Form>
                        </List>
                        {/* {record && (
                            <CustomerProducts modalProps={modalProps} record={record} />
                        )} */}                        
                    </Col>
                </Row>
            </>
        ))}
        {(showImport && resultImport && (            
        <Row gutter={[16, 16]}>
            <Col xl={24} xs={24}>
                <Card title="Resultado da importação:" style={{margin: "15px auto"}}>
                    <Text style={{ fontSize: 14, fontWeight: 400}}>1. Veja abaixo o resultado da importação de cada cliente da planilha</Text><br/>
                    <Text style={{ fontSize: 14, fontWeight: 400}}>2. Caso haja alguma falha na criação do cliente e/ou conta bancária, basta clicar no botão de "+" para ver os detalhes</Text><br/>
                    <Text style={{ fontSize: 14, fontWeight: 400}}>3. Corrija as informações na planilha e repita o processo novamente</Text><br/><br/>
                    <Text style={{ fontSize: 14, fontWeight: 400}}>Obs: Caso algum cadastro de cliente e/ou conta seja identificado como duplicado, ele será ignorado na importação</Text><br/><br/>
                    <Button type="primary" icon={<ReloadOutlined />} onClick={() => { setShowImport(false); showModalSheets(); }}>Realizar nova importação</Button>
                    {/* <Button type="primary" ghost icon={<UnorderedListOutlined />} onClick={() => setShowImport(false)} style={{ marginLeft: 15}}>Ver Clientes Cadastrados</Button> */}
                    <RefreshButton type="primary" ghost icon={<UnorderedListOutlined />} resource="customers" onClick={() => setShowImport(false)} style={{ marginLeft: 15}}>Ver Clientes Cadastrados</RefreshButton>
                </Card>
                <List
                    title={"Clientes da planilha:"}
                    headerProps={{
                        extra: (<></>
                        //     <Space>
                        //         <ExportButton
                        //             onClick={triggerExport}
                        //             loading={exportLoading}
                        //         />
                        //     </Space>
                        ),
                    }}>
                    <Table 
                        pagination={false}
                        dataSource={resultImport}
                        rowKey="id"
                        expandable={{
                            expandedRowRender: (record : any) =>
                            (
                                <>
                                <Descriptions title="Dados Cliente">
                                    <Descriptions.Item label="Status" span={3}>{(record.customer.success ? <Tag color="success">{record.customer?.type==="new" ? "Novo Cliente Importado" : "Cliente Já Cadastrado"}</Tag> : <Tag color="error">Falha na Importação</Tag>)}</Descriptions.Item>
                                    <Descriptions.Item label="Erro (em caso de falha)" span={3}>{record.customer.error}</Descriptions.Item>
                                    <Descriptions.Item label="Nome">{record.data["undefinedNome Completo"]} ({record.data["Razão Social"]})</Descriptions.Item>
                                    <Descriptions.Item label="Email">{record.data["Email"]}</Descriptions.Item>
                                    <Descriptions.Item label="Nro Celular">{record.data["Nro Celular"]}</Descriptions.Item>
                                    <Descriptions.Item label="Tipo">{record.data["Pessoa Física ou Pessoa Jurídica (PF/PJ)?"]}</Descriptions.Item>
                                    <Descriptions.Item label="CNPJ/CPF">{record.data["Nro do CPF ou CNPJ"]}</Descriptions.Item>
                                </Descriptions>                     
                                <Descriptions title="Dados Bancários">
                                    <Descriptions.Item label="Status" span={3}>{(record.account.success ? <Tag color="success">{record.customer?.type==="new" ? "Nova Conta Importada" : "Conta Já Cadastrada"}</Tag> : <Tag color="error">Falha na Importação</Tag>)}</Descriptions.Item>
                                    <Descriptions.Item label="Erro (em caso de falha)" span={3}>{record.account.error}</Descriptions.Item>
                                    <Descriptions.Item label="Banco">{record.data["Banco"]}</Descriptions.Item>
                                    <Descriptions.Item label="Agência">{record.data["Agência"]}</Descriptions.Item>
                                    <Descriptions.Item label="Conta Corrente">{record.data["Conta Corrente"]}</Descriptions.Item>
                                </Descriptions>       
                                </>
                            ),
                            // rowExpandable: true,
                        }}
                    >
                        <Table.Column
                            dataIndex='data["undefinedNome Completo"]'                            
                            title={"Nome"}
                            render={(value,record : any) => {
                                console.log("record",record);
                                return (record.customer.id ? (<Link to={`/customers/edit/${record.customer.id}`} target="_blank">{record.data["undefinedNome Completo"]}</Link>) : record.data["undefinedNome Completo"]);
                            }}
                        />
                        <Table.Column
                            dataIndex="customer.success"
                            title={"Cliente Importado?"}
                            render={(value,record : any) => {
                                console.log("record",record);
                                return (record.customer.success ? <Tag color="success">{record.customer?.type==="new" ? "Novo Cliente Importado" : "Cliente Já Cadastrado"}</Tag> : <Tag color="error">Falha na importação</Tag>);
                            }}
                        />
                        <Table.Column
                            dataIndex="account.success"
                            title={"Conta Bancária Importada?"}
                            render={(value,record : any) => {
                                console.log("record",record);
                                return (record.account.success ? <Tag color="success">{record.customer?.type==="new" ? "Nova Conta Importada" : "Conta Já Cadastrada"}</Tag> : <Tag color="error">Falha na importação</Tag>);
                            }}
                        />
                        {/* <Table.Column<ICustomer>
                        title="Opções"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="large"
                                    recordItemId={record.id}
                                />
                                <ShowButton
                                    hideText
                                    size="large"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                        /> */}
                    </Table>
                </List>                
                {/* {renderUpload()} */}
            </Col>            
        </Row>
        ))}
        <Modal
            title="Importar Clientes em Lote/Planilha"
            open={isModalSheetsOpen}
            onCancel={handleCancel}
            width={"75%"}
            footer={[
                <Button onClick={handleCancel}>
                  Fechar
                </Button>
            ]}>
            {renderUpload()}
        </Modal>
        <Modal
            title="Importar Clientes por PDF"
            open={isModalPdfOpen}
            onCancel={handleCancel}
            width={"75%"}
            footer={[
                <Button onClick={handleCancel}>
                  Fechar
                </Button>
            ]}>
            {renderPdf()}
        </Modal>
        </>
    );
};

const Filter: React.FC<{ formProps: FormProps, customerView: string }> = (props) => {
    const t = useTranslate();

    const { RangePicker } = DatePicker;

    const statusList = [
        { label: "Dados Pendentes", value: 0 },
        { label: "Aprovado", value: 1, },
        { label:  "Negado", value: 2, },
        { label: "Em Análise", value: 3, },
        { label: "Enviar Documentos", value: 4, },
        { label: "Documentos em Análise", value: 5, },
        { label: "Revisar Dados", value: 6 },
    ]

    return (<>
        <Form layout="vertical" {...props.formProps}>
            <Row gutter={[10, 0]} align="bottom">
                <Col xs={24} xl={24} md={8}>
                    <Form.Item label={t("users.filter.search.label")} name="q">
                        <Input
                            placeholder={t("users.filter.search.placeholder")}
                            prefix={<SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                {/* <Col xs={24} xl={24} md={12}>
                    <Form.Item
                        label={t("users.filter.createdAt.label")}
                        name="createdAt"
                    >
                        <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col> */}
                <Col xs={24} xl={24} md={6}>
                    <Form.Item
                        label={t("customers.fields.status")}
                        name="status"          
                        hidden={props.customerView==="Pagadores" ? true : false}
                    >
                        <Select
                            allowClear
                            placeholder={t("customers.fields.status")}
                            options={statusList}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} xl={24} md={8}>
                    <Form.Item
                        label={t("customers.filter.active.label")}
                        name="active"
                        // valuePropName="active"
                    >
                        {/* <Switch defaultChecked /> */}
                        <Select
                            allowClear
                            placeholder={t("users.filter.active.placeholder")}
                            options={[
                                {
                                    label: t("customers.filter.active.true"),
                                    value: "true",
                                },
                                {
                                    label: t("customers.filter.active.false"),
                                    value: "false",
                                },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} xl={24} md={8}>
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            htmlType="submit"
                            type="primary"
                        >
                            {t("users.filter.submit")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        {(props.customerView==="Pagadores") ? (
            <Alert
                message={"Pagador: cliente que faz pagamentos de cobranças"}
                type="info"
                showIcon
                style={{marginTop: 15, marginBottom: 15}}
            />
        ) : (<>
            <Alert
                message={"Recebedor: cliente que recebe pagamentos"}
                type="info"
                showIcon
                style={{marginTop: 15, marginBottom: 15}}
            />
            <Alert
                message={"Recebedores precisam estar com status 'Aprovado' para serem incluídos em uma cobrança"}
                type="error"
                showIcon
                style={{marginTop: 15, marginBottom: 15}}
            />
        </>)}
    </>);
};
